@use "../abstracts/colors" as *;

@each $category, $list-colors in $colors {
  @each $color, $list-shades in $list-colors {
    @each $shade, $value in $list-shades {
      .color-#{$category}-#{"" + $color}-#{$shade} {
        color: $value;
      }
    }
  }
}
