@use "breakpoints" as *;
@use "sass:map";

@mixin mq($size-key) {
  $size: map.get($breakpoints, $size-key);
  @media (min-width: $size) {
    @content;
  }
}

@mixin mq-xs() {
  @include mq(xs) {
    @content;
  }
}

@mixin mq-sm() {
  @include mq(sm) {
    @content;
  }
}

@mixin mq-md() {
  @include mq(md) {
    @content;
  }
}

@mixin mq-lg() {
  @include mq(lg) {
    @content;
  }
}

@mixin mq-xl() {
  @include mq(xl) {
    @content;
  }
}

@mixin mq-xxl() {
  @include mq(xxl);
}

@mixin on-edge($edge, $dist: 10px) {
  position: absolute;
  #{$edge}: $dist;
}

@mixin box-pop($amt: 1) {
  box-shadow: 0 2px 16px * $amt 0 rgb(5 5 5 / 15%);
}
