@use "../abstracts/mixins" as *;
@use "../abstracts/colors" as *;

.grid-alt {
  display: grid;
  gap: 1.5em;
  grid-template-columns: 1fr;

  .grid-alt-text {
    height: 100%;
    position: relative;

    > a {
      &::after {
        content: "❯";
      }

      font-size: 2em;
      font-weight: 900;
      color: $colors-hc-blue;
      text-decoration: none;
      transition: transform 0.4s ease-in-out;
      display: block;
      width: 2em;
      float: right;
    }

    > a:hover {
      transform: translateX(5px);
    }
  }

  @include mq-lg() {
    grid-template-columns: repeat(2, 1fr);
    @for $n from 1 through 10 {
      img:nth-child(#{$n}) {
        order: $n;
      }
      div:nth-child(#{$n + 1}) {
        order: $n + 1;
      }
      @if (($n + 5) % 4) == 0 {
        img:nth-child(#{$n}) {
          order: $n + 1;
        }
        div:nth-child(#{$n + 1}) {
          order: $n;

          > a {
            float: left;
            &::after {
              content: "❮";
            }
          }
          > a:hover {
            transform: translateX(-5px);
          }
        }
      }
    }
  }
}
