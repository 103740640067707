@use "../abstracts/colors" as *;

.btn-outline-primary {
  color: $colors-hc-blue;
  border-color: $colors-hc-blue;
}

.btn-outline-primary {
  color: $colors-hc-blue;
  border-color: $colors-hc-blue;
}

.btn-outline-primary:hover {
  background-color: $colors-hc-blue;
  border-color: $colors-hc-blue;
}

.btn-outline-primary:focus {
  box-shadow: 0 0 0 0.25rem rgba($colors-hc-blue, 0.5);
}
