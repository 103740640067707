@import "https://use.typekit.net/eyh1bcv.css";
@import "https://use.typekit.net/evz5dus.css";
@charset "UTF-8";
.color-primary-blue-primary {
  color: #0c1b60;
}
.color-primary-blue-a1 {
  color: #142fac;
}
.color-primary-red-primary {
  color: #6b1010;
}
.color-btn-nav-toggle-default {
  color: white;
}
.color-btn-nav-toggle-hover {
  color: white;
}
.color-btn-nav-toggle-focus {
  color: #faf8f8;
}
.color-nav-bg-0_9 {
  color: rgba(255, 255, 255, 0.9);
}
.color-nav-bg-0_97 {
  color: rgba(255, 255, 255, 0.97);
}
.color-nav-bg-0_975 {
  color: rgba(255, 255, 255, 0.975);
}
/* Box sizing rules */
*,
*::before,
*::after {
  box-sizing: border-box;
}
/* Remove default margin */
body,
h1,
h2,
h3,
h4,
p,
figure,
blockquote,
dl,
dd {
  margin: 0;
}
/* Remove list styles on ul, ol elements with a list role, which suggests default styling will be removed */
ul[role=list],
ol[role=list] {
  list-style: none;
}
/* Set core root defaults */
html:focus-within {
  scroll-behavior: smooth;
}
/* Set core body defaults */
body {
  min-height: 100vh;
  text-rendering: optimizeSpeed;
  line-height: 1.5;
}
/* A elements that don't have a class get default styles */
a:not([class]) {
  text-decoration-skip-ink: auto;
}
/* Make images easier to work with */
img,
picture {
  max-width: 100%;
  display: block;
}
/* Inherit fonts for inputs and buttons */
input,
button,
textarea,
select {
  font: inherit;
}
/* Remove all animations, transitions and smooth scroll for people that prefer not to see them */
@media (prefers-reduced-motion: reduce) {
  html:focus-within {
    scroll-behavior: auto;
  }

  *,
*::before,
*::after {
    animation-duration: 0.01ms !important;
    animation-iteration-count: 1 !important;
    transition-duration: 0.01ms !important;
    scroll-behavior: auto !important;
  }
}
h1 {
  font-family: "Arpona" !important;
}
p {
  font-family: "ballinger-mono" !important;
}
h3 a {
  color: #0c1b60;
}
h3 a:hover {
  color: #142fac;
}
/*  =================
    Table of Contents
    ================= 

    1. Variables
      1.1 Nav variables
        1.1a Logo
        1.1b Border styles
        1.1c Animations
        1.1d Padding
        1.1e Height
    2. Navbar
      2.1 Root styles
      2.2 Container style
        2.2a Primary
        2.2b Secondary
      2.3 Styles after scroll
        2.3a General containers
        2.3b Primary container
        2.3c Secondary container
    3. Footer

   */
/*  =========
    Variables
    ========= */
/* --- Nav variables --- */
/*  ======
    Navbar
    ====== */
nav {
  /* Root Styles */
  display: block !important;
  border-bottom: 1px solid lightgray;
  background-color: rgba(255, 255, 255, 0.975);
  transition: background-color 0.4s ease-in-out;
}
@media (min-width: 992px) {
  nav {
    background-color: rgba(255, 255, 255, 0.9);
  }
}
nav .container-fluid.primary {
  /* --- Primary (bottom) container --- */
  padding-top: 7px;
  padding-bottom: 7px;
  text-align: center;
}
nav .container-fluid.primary .navbar-collapse {
  justify-content: center;
}
nav .container-fluid.primary .navlogo-primary {
  height: 0px;
  transition: height 0.4s ease-in-out;
  position: absolute;
  left: 10px;
}
nav .container-fluid.primary .navlogo-primary img {
  vertical-align: text-top;
}
nav .container-fluid.primary .dropdown-menu {
  border-radius: 3px;
  box-shadow: 0 2px 16px 0 rgba(5, 5, 5, 0.15);
  background-color: rgba(255, 255, 255, 0.9);
}
nav .container-fluid.secondary {
  /* Secondary (top) container */
  display: flex;
  justify-content: center;
  height: 74px;
  transition: height 0.4s ease-in-out, border-width 0.25s ease-in-out;
  border-width: 0;
}
@media (min-width: 992px) {
  nav .container-fluid.secondary {
    border-bottom: 1px solid lightgray;
  }
}
nav .container-fluid.secondary .navlogo-secondary {
  /* Make height of logo height of container - padding (logo-height)
  Intended to scale if logo height is deprecated in the future */
  height: calc(100% - 14px);
}
nav .container-fluid.secondary .navbar-toggler {
  position: absolute;
  left: 10px;
}
nav .container-fluid.secondary .navbar-toggler:focus {
  text-decoration: none;
  outline: 0;
  box-shadow: none;
  background-color: #faf8f8;
}
nav.expanded .container-fluid.secondary {
  /* Secondary (top) container */
  border-bottom: 1px solid lightgray;
}
nav.navbar-scrolled {
  /* Styles after use has scrolled */
}
@media (min-width: 992px) {
  nav.navbar-scrolled {
    box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.08);
    background-color: rgba(255, 255, 255, 0.97);
  }
}
nav.navbar-scrolled .container-fluid.primary {
  /* Primary (bottom) container */
}
@media (min-width: 992px) {
  nav.navbar-scrolled .container-fluid.primary .navlogo-primary {
    height: 60px;
  }
}
nav.navbar-scrolled .container-fluid.primary .dropdown-menu {
  background-color: rgba(255, 255, 255, 0.97);
}
nav.navbar-scrolled .container-fluid.secondary {
  /* Secondary (top) container */
}
@media (min-width: 992px) {
  nav.navbar-scrolled .container-fluid.secondary {
    border-width: 0;
    height: 0;
  }
}
/*  ======
    Footer
    ====== */
footer img {
  max-width: 30%;
  display: block;
  margin: auto 0;
}
@media (min-width: 768px) {
  footer img {
    max-width: 70%;
  }
}
.grid-alt {
  display: grid;
  gap: 1.5em;
  grid-template-columns: 1fr;
}
.grid-alt .grid-alt-text {
  height: 100%;
  position: relative;
}
.grid-alt .grid-alt-text > a {
  font-size: 2em;
  font-weight: 900;
  color: #0c1b60;
  text-decoration: none;
  transition: transform 0.4s ease-in-out;
  display: block;
  width: 2em;
  float: right;
}
.grid-alt .grid-alt-text > a::after {
  content: "❯";
}
.grid-alt .grid-alt-text > a:hover {
  transform: translateX(5px);
}
@media (min-width: 992px) {
  .grid-alt {
    grid-template-columns: repeat(2, 1fr);
  }
  .grid-alt img:nth-child(1) {
    order: 1;
  }
  .grid-alt div:nth-child(2) {
    order: 2;
  }
  .grid-alt img:nth-child(2) {
    order: 2;
  }
  .grid-alt div:nth-child(3) {
    order: 3;
  }
  .grid-alt img:nth-child(3) {
    order: 3;
  }
  .grid-alt div:nth-child(4) {
    order: 4;
  }
  .grid-alt img:nth-child(3) {
    order: 4;
  }
  .grid-alt div:nth-child(4) {
    order: 3;
  }
  .grid-alt div:nth-child(4) > a {
    float: left;
  }
  .grid-alt div:nth-child(4) > a::after {
    content: "❮";
  }
  .grid-alt div:nth-child(4) > a:hover {
    transform: translateX(-5px);
  }
  .grid-alt img:nth-child(4) {
    order: 4;
  }
  .grid-alt div:nth-child(5) {
    order: 5;
  }
  .grid-alt img:nth-child(5) {
    order: 5;
  }
  .grid-alt div:nth-child(6) {
    order: 6;
  }
  .grid-alt img:nth-child(6) {
    order: 6;
  }
  .grid-alt div:nth-child(7) {
    order: 7;
  }
  .grid-alt img:nth-child(7) {
    order: 7;
  }
  .grid-alt div:nth-child(8) {
    order: 8;
  }
  .grid-alt img:nth-child(7) {
    order: 8;
  }
  .grid-alt div:nth-child(8) {
    order: 7;
  }
  .grid-alt div:nth-child(8) > a {
    float: left;
  }
  .grid-alt div:nth-child(8) > a::after {
    content: "❮";
  }
  .grid-alt div:nth-child(8) > a:hover {
    transform: translateX(-5px);
  }
  .grid-alt img:nth-child(8) {
    order: 8;
  }
  .grid-alt div:nth-child(9) {
    order: 9;
  }
  .grid-alt img:nth-child(9) {
    order: 9;
  }
  .grid-alt div:nth-child(10) {
    order: 10;
  }
  .grid-alt img:nth-child(10) {
    order: 10;
  }
  .grid-alt div:nth-child(11) {
    order: 11;
  }
}
.cover {
  background-attachment: fixed;
  /* Full size and config */
  height: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.btn-outline-primary {
  color: #0c1b60;
  border-color: #0c1b60;
}
.btn-outline-primary {
  color: #0c1b60;
  border-color: #0c1b60;
}
.btn-outline-primary:hover {
  background-color: #0c1b60;
  border-color: #0c1b60;
}
.btn-outline-primary:focus {
  box-shadow: 0 0 0 0.25rem rgba(12, 27, 96, 0.5);
}
/*# sourceMappingURL=index.77c9e484.css.map */
